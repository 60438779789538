import request from '@/utils/request.js'
import cookie from './cookie'
const APPID = 'wx52a0055d3fb28ef8' // 正式号APPID
// const APPID = 'wx14a88f04302c39ed'; // 测试号APPID
const WX_AUTH_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_userinfo&state=123&forcePopup=true&forceSnapShot=true#wechat_redirect'

/**
 * 微信授权
 */
export default function wxAuth() {
  return new Promise((resolve, reject) => {
    // cookie.set('openid','o_7qE6gaPB15HwjGRhGh_xrrmWjE');
    let openid = cookie.get('openid')
    console.log('openid', openid)
    if (openid) {
      resolve({
        code: 200,
        openid,
      })
      return
    }
    let code = getUrlParam('code')
    //未经过微信授权
    if (!code) {
      let currentUrl = encodeURIComponent(window.location.href)
      window.location.replace(WX_AUTH_URL.replace('REDIRECT_URI', currentUrl))
    } else {
      request.ajax.post(`/wx/login/authorization?code=${code}`).then(async (res) => {
        console.log('res', res)
        if (res.data.code == 200) {
          console.log('微信授权完成')
          cookie.set('openid', res.data.data.openid)
          resolve({
            code: 200,
            openid: res.data.data.openid,
          })
        } else {
          console.log('换取openid失败')
        }
      })
    }
  })
}

function getUrlParam(key) {
  //获取当前页面url中的参数
  var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
  var result = window.location.search.substr(1).match(reg)
  return result ? decodeURIComponent(result[2]) : ''
}

const wxUnit = async () => {
  const res = await wxAuth()
  console.log('res', res)
  if (res.code == 200) {
    const openid = res.openid
    const result = await request.ajax.post(`/wx/login/wxLogin?openId=${openid}`)
    console.log('result', result)
    if (result.data.code == 404) {
      this.$toast(`${result.data.msg}`)
      setTimeout(() => {
        this.$router.push({
          path: '/register',
        })
      }, 1000)
    } else if (result.data.code == 200) {
      cookie.set('satoken', result.data.data.tokenValue)
      cookie.set('loginId', result.data.data.loginId)
      // wx.config({
      //   debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //   appId: con.appId, // 必填，公众号的唯一标识
      //   timestamp: con.timestamp, // 必填，生成签名的时间戳
      //   nonceStr: con.nonceStr, // 必填，生成签名的随机串
      //   signature: con.signature, // 必填，签名，见附录1
      //   jsApiList: ["scanQRCode", "chooseWXPay"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      // });
      //wx.ready(() => {
      //this.$dialog.loading.close();
      //});
      //wx.error((e) => {
      //this.$dialog.loading.close();

      //this.$dialog.loading.open("请重新进入公众号");
      //});
    }
  }
}
