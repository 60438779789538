<template>
  <div id="app">
    <router-view/>
    <div class="botnav" v-if="currentRoute !=='register'">
      <div class="item" :class="{ 'active': currentRoute === 'home1' }" @click="handleToHome1">
        <van-icon name="friends-o" size="1.2rem"  />
        <div> 校友会</div>
      </div>
      <div class="item"    :class="{ 'active': currentRoute === 'home2' }" @click="handleToHome2">
        <van-icon name="smile-comment-o" size="1.2rem" />
        <div>服务校友</div>
      </div>
      <div class="item" :class="{ 'active': currentRoute === 'home3' }"  @click="handleToHome3" >
        <van-icon name="notes-o" size="1.2rem" />
        <div>资讯互动</div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  data() {
    return {
      currentRoute: this.$route.meta.name 
    }
  },
    mounted(){
    },
    watch: {
        '$route'(to, from) {
            this.currentRoute = to.meta.name;
        }
    },
    methods:{
      handleToHome1() {
        if(this.currentRoute !=='home1') {
          this.$router.push({
          path:'/'
        })
        }
      },
      handleToHome2() {        
        if(this.currentRoute !=='home2') {
          this.$router.push({
          path:'/home2'
        })
        }
      },
      handleToHome3() {
        if(this.currentRoute !=='home3') {
          this.$router.push({
          path:'/home3'
        })
        }
      }
    }
}
</script >
<style src="./style/common.css" scope></style>
<style lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.botnav {
  position: fixed;
  width: 100%;
  height: 3.75rem;
  bottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: .75rem;
  border-top: 1px solid #999; /* 上边框颜色可以根据需要调整 */
  border-top-left-radius: .375rem;/* 左上角圆角半径，根据需要调整 */
  border-top-right-radius: .375rem; /* 右上角圆角半径，根据需要调整 */
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.active {
      color: #1989fa; 
    }
  }
}
.empty-banner{
    background-color: #66c6f2;
    color: #fff;
    font-size: 1.25rem;
    text-align: center;
    line-height: 6.25rem;
}
</style>
