import Vue from "vue";
import VueRouter from "vue-router";
import cookie from "../js/cookie";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    component: () => import("@/pages/Home1"),
    meta: { title: "西南科技大学广东校友会", name: "home1" },
  },
  // 首页
  {
    path: "/home2",
    component: () => import("@/pages/Home2"),
    meta: { title: "西南科技大学广东校友会", name: "home2" },
  },
  {
    path: "/home3",
    component: () => import("@/pages/Home3"),
    meta: { title: "西南科技大学广东校友会", name: "home3" },
  },
  // 注册
  {
    path: "/register",
    component: () => import("@/pages/Register/Register.vue"),
    meta: { title: "注册", name: "register" },
  },
  // 个人中心
  {
    path: "/Personcenter",
    component: () => import("@/pages/Register/Personcenter.vue"),
    meta: { title: "个人中心" },
  },
  // 爱心活动
  {
    path: "/Loveproject",
    component: () => import("@/pages/Loveproject/Loveproject.vue"),
    meta: { title: "爱心活动" },
  },

  // 爱心活动详情
  {
    path: "/Loveproject/LoveprojectDetail",
    component: () => import("@/pages/Loveproject/LoveprojectDetail.vue"),
    meta: { title: "项目详情" },
  },

  // 捐赠页面
  {
    path: "/Loveproject/Donation",
    component: () => import("@/pages/Loveproject/Donation.vue"),
    meta: { title: "校友捐赠" },
  },
  // 捐赠物品
  {
    path: "/Loveproject/DonationArticle",
    component: () => import("@/pages/Loveproject/DonationArticle.vue"),
    meta: { title: "我要捐赠" },
  },
  // 捐赠现金
  {
    path: "/Loveproject/DonationMoney",
    component: () => import("@/pages/Loveproject/DonationMoney.vue"),
    meta: { title: "我要捐赠" },
  },
  // 校友基金
  {
    path: "/AlumniFund",
    component: () => import("@/pages/AlumniFund/AlumniFund.vue"),
    meta: { title: "校友基金" },
  },
  // 校友基金捐赠页面
  {
    path: "/AlumniFund/EndowmentAlumniFundDonation",
    component: () =>
      import("@/pages/AlumniFund/EndowmentAlumniFundDonation.vue"),
    meta: { title: "校友捐赠" },
  },
  // 校友基金捐赠现金
  {
    path: "/AlumniFund/EndowmentAlumniFund",
    component: () => import("@/pages/AlumniFund/EndowmentAlumniFund.vue"),
    meta: { title: "我要捐赠" },
  },
  // 企业招聘
  {
    path: "/EnterpriseRecruitment",
    component: () =>
      import("@/pages/EnterpriseRecruitment/EnterpriseRecruitment.vue"),
    meta: { title: "企业招聘" },
  },
  // 企业招聘详情
  {
    path: "/EnterpriseRecruitment/Jobdetails",
    component: () => import("@/pages/EnterpriseRecruitment/Jobdetails.vue"),
    meta: { title: "招聘详情" },
  },
  // 优秀毕业生
  {
    path: "/ExcellentAlumni",
    component: () => import("@/pages/ExcellentAlumni/ExcellentAlumni.vue"),
    meta: { title: "优秀校友" },
  },
  // 优秀校友详情
  {
    path: "/ExcellentAlumni/ExcellentAlumniDetail",
    component: () =>
      import("@/pages/ExcellentAlumni/ExcellentAlumniDetail.vue"),
    meta: { title: "查看详情" },
  },
  // 我找校友
  {
    path: "/ExcellentAlumni/search",
    component: () =>
      import("@/pages/SearchExcellentAlumni/SearchExcellentAlumni.vue"),
    meta: { title: "我找校友" },
  },
  // 校友找工作
  {
    path: "/ExcellentAlumni/AlumniLookingForJobs",
    component: () =>
      import("@/pages/AlumniLookingForJobs/AlumniLookingForJobs.vue"),
    meta: { title: "校友找工作" },
  },

  // 校友企业列表
  {
    path: "/AlumniEnterprises",
    component: () => import("@/pages/AlumniEnterprises/AlumniEnterprises.vue"),
    meta: { title: "校友企业" },
  },
  // 企业详情
  {
    path: "/AlumniEnterprises/DetailsofAlumniCompanies",
    component: () =>
      import("@/pages/AlumniEnterprises/DetailsofAlumniCompanies.vue"),
    meta: { title: "校友企业" },
  },

  // 信息公示
  {
    path: "/InformationAnnouncement",
    component: () =>
      import("@/pages/InformationAnnouncement/InformationAnnouncement.vue"),
    meta: { title: "信息公示" },
  },
  // 资讯
  {
    path: "/Information",
    component: () => import("@/pages/Information/Information.vue"),
    meta: { title: "资讯" },
  },
  // 资讯详情
  {
    path: "/Information/InformationDetails",
    component: () => import("@/pages/Information/InformationDetails"),
    meta: { title: "资讯详情" },
  },
  // 校友会介绍
  {
    path: "/IntroductionTheAlumniAssociation",
    component: () =>
      import(
        "@/pages/PersonInChargeIntroduction/IntroductionTheAlumniAssociation.vue"
      ),
    meta: { title: "校友会介绍" },
  },
  // 校友会介绍
  {
    path: "/IntroductionTheAlumniAssociation/detail",
    component: () =>
      import(
        "@/pages/PersonInChargeIntroduction/IntroductionTheAlumniAssociationDetail.vue"
      ),
    meta: { title: "校友会介绍" },
  },

  // 负责人介绍
  {
    path: "/PersonInChargeIntroduction",
    component: () =>
      import(
        "@/pages/PersonInChargeIntroduction/PersonInChargeIntroduction.vue"
      ),
    meta: { title: "负责人介绍" },
  },
  // 负责人介绍
  {
    path: "/PersonInChargeIntroduction/detail",
    component: () =>
      import(
        "@/pages/PersonInChargeIntroduction/PersonInChargeIntroductionDetail.vue"
      ),
    meta: { title: "负责人介绍" },
  },
  // 校友会章程
  {
    path: "/CharterTheAlumniAssociation",
    component: () =>
      import(
        "@/pages/CharterTheAlumniAssociation/CharterTheAlumniAssociation.vue"
      ),
    meta: { title: "校友会章程" },
  },
  {
    path: "/*",
    component: () => import("@/pages/NotFound"),
    meta: { title: "404" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // 在每次路由切换前更新标题
  document.title = to.meta.title || "西南科技大学校友会";
  next();
});
Vue.prototype.$go = function (link) {
  if (/^(http|https):.*$/.test(link)) {
    //打开外部链接
    window.location.href = link;
  } else {
    router.push(link);
  }
};

export default router;
