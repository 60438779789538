import axios from 'axios';
import cookie from '../js/cookie'

const createAxiosInstance = (token) => {
  return axios.create({
    // baseURL: "http://192.168.1.206:80/yuan",
    baseURL: "http://58.63.50.170:8333/yuan",
    timeout: 30000,
    headers: {
      'satoken': token
    }
  });
};
let request = {
  get ajax() {
    const token = cookie.get("satoken");
    return createAxiosInstance(token);
  },
};
// request.district = async()=>{
//     let res = await request.ajax.get("/h/getstation");
//     window.localStorage.district = JSON.stringify(res.data.data);
// }
export default request;
